<template>
  <div v-if="user == null"></div>
  <div v-else>
    <v-container class="pricing2 !t-max-w-[1280px]">
      <div class="t-pt-24 t-flex t-items-center t-flex-col">
        <h1 class="t-font-semibold t-text-5xl t-text-[#101828] t-mb-6">Upgrade Your Plan</h1>
        <p class="t-text-xl t-text-[#475467] !t-mb-0">
          Simple, transparent pricing that grows with you.
        </p>

        <v-tabs v-model="billing" class="t-my-12" hide-slider height="76">
          <div class="t-flex t-h-14 t-justify-center t-w-full">
            <div
              class="t-flex t-bg-gray-50 t-rounded-xl t-border t-border-solid t-border-[#eaecf0] t-p-[6px] t-relative"
            >
              <v-tab
                v-for="(n, i) in ['Pay As You Go', 'Monthly billing']"
                :key="n"
                class="t-py-2 t-p-3 t-tracking-wide t-rounded-md t-capitalize !t-text-[#667085] t-text-base t-font-semibold"
                :class="{ 't-mr-1': !i }"
                active-class="t-bg-white !t-text-[#344054] t-shadow-[0px_1px_2px_0px_#1018280F,0px_1px_3px_0px_#1018281A]"
              >
                {{ n }}
              </v-tab>

              <span
                class="t-bg-[#216fed]/10 t-rounded-2xl t-border t-border-solid t-border-[#216fed] t-text-[#216fed] t-absolute t-h-7 t-px-2 t-font-medium t-text-sm d-flex t-justify-center t-items-center -t-bottom-[18px] -t-right-8"
              >
                Save 50%
              </span>
            </div>
          </div>

          <v-tabs-items v-model="billing" touchless class="t-w-full t-pt-[76px]">
            <v-tab-item v-for="n in 2" :key="n">
              <v-row class="t-justify-center">
                <v-col v-for="(plan, i) in displayPricing" :key="i" cols="12" md="6" lg="3" class="t-relative">
                  <div class="t-px-6 t-pb-12">
                    <div class="t-text-[#101828] t-font-semibold t-text-xl t-mb-4">{{ plan.title }}</div>

                    <hr class="t-border-0 t-border-t t-border-solid t-border-[#eaecf0] t-mx-[-100%]" />

                    <div class="t-mt-8 t-mb-6 t-flex t-gap-3 t-flex-col t-text-[#475467]">
                      <div class="t-mb-[7px]">
                        <span class="t-text-[#101828] t-text-5xl t-font-semibold">
                          ${{ plan.price }}
                        </span>
                        {{ isSubscription ? "per month" : "(One-Time)" }}
                      </div>
                      <span v-for="feature in plan.features" :key="feature.number">
                        <span class="t-font-bold">{{ feature.number }}</span>
                        {{ feature.text }}
                        <v-tooltip v-if="feature.info" right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="grey"
                              size="20"
                              dark
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-information
                            </v-icon>
                          </template>
                          <span>{{ feature.info }}</span>
                        </v-tooltip>
                      </span>
                    </div>

                    <v-btn
                      block
                      outlined
                      class="t-capitalize t-font-semibold t-rounded-lg hover:t-bg-[#216fed]/10"
                      :class="{
                        't-bg-[#216fed]/10': isCurrentPlan(plan.stripeId),
                        't-bg-white': !isCurrentPlan(plan.stripeId)
                      }"
                      @click="checkout(plan)"
                      :color="$theme.primary"
                    >
                      {{
                        isCurrentPlan(plan.stripeId)
                          ? "Restart my billing cycle"
                          : "Select"
                      }}
                    </v-btn>
                  </div>

                  <div
                    v-if="i === 1"
                    class="t-absolute d-flex t-items-center t-flex-col t-text-[#216FED] t-font-semibold t-text-sm -t-top-[54px] t-left-[100px] t-z-30"
                  >
                    <span>Most Popular!</span>
                    <v-img src="@/assets/icons/curved-arrow.svg" />
                  </div>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </div>

      <div class="mb-10 t-pt-8 t-pb-10 t-flex t-items-center t-flex-col t-space-y-8">
        <div>
          <v-img src="@/assets/images/pricing2/avatar-group.png" width="120" />
        </div>

        <div class="t-text-center">
          <h3 class="t-text-[#101828] t-font-semibold t-text-xl">Need a bigger plan?</h3>
          <p class="!t-mb-0 t-text-[#475467] t-text-lg">
            Looking for an enterprise plan with a dedicated account manager, higher rate limits, and On-Demand quotas?
          </p>
        </div>

        <v-btn @click="contactSales" color="primary" depressed class="t-capitalize t-font-semibold t-rounded-lg" width="169" height="48">
          See Enterprise Plans
        </v-btn>
      </div>

      <div class="t-mb-[72px]">

        <div class="t-bg-gray-50 t-py-[22px] t-px-6 t-text-[#101828] t-font-semibold t-text-lg">All plans include</div>

        <v-row class="t-overflow-hidden" no-gutters>
          <v-col v-for="(feature, i) in features" :key="i" cols="12" md="6" lg="4">
            <div v-for="text in feature" :key="text">
              <div class="t-py-[22px] t-px-6 t-flex t-gap-2 t-items-center">
                <div>
                  <v-img src="@/assets/icons/check.svg" width="18" height="18" />
                </div>
                <span class="t-text-[#101828] t-font-medium">{{ text }}</span>
              </div>

              <hr class="t-border-0 t-border-t t-border-solid t-border-[#eaecf0] t-mx-[-100%]" />
            </div>
          </v-col>
        </v-row>
      </div>

    </v-container>

    <div class="t-bg-gray-50 t-py-24">
      <v-container class="!t-max-w-[1280px]">
        <div class="t-mb-24">
          <div class="t-text-center t-mb-16">
            <h2 class="t-font-semibold t-text-4xl t-text-[#101828] t-mb-5">Frequently asked questions</h2>
            <p class="t-text-[#475467] t-text-xl !t-mb-0">Everything you need to know about the product and billing.</p>
          </div>

          <v-row class="md:t-space-x-">
            <v-col v-for="(item, i) in faqs" :key="i" cols="12" lg="6">
              <div
                v-for="(faq, idx) in item"
                :key="`faq-${idx}`"
                class="t-pt-6 t-border-t t-border-solid t-border-[#eaecf0] t-mb-8"
              >
                <div class="t-flex t-justify-between t-cursor-pointer" @click="faq.expanded = !faq.expanded">
                  <div>
                    <span class="t-text-[#101828] t-font-medium t-text-lg">{{ faq.title }}</span>

                    <v-slide-y-transition hide-on-leave>
                      <div v-if="faq.expanded" class="t-text-[#475467] t-mt-2">{{ faq.description }}</div>
                    </v-slide-y-transition>
                  </div>

                  <div>
                    <v-img
                      :src="require(`@/assets/icons/${faq.expanded ? 'minus' : 'plus'}-circle.svg`)"
                      width="24"
                      height="24"
                    />
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>

        <div v-if="!user.stripe.subscriptionId" class="t-mt-24 t-pt-8 t-pb-10 t-flex t-items-center t-flex-col t-space-y-8 t-bg-white t-rounded-2xl">
          <div>
            <v-img src="@/assets/images/pricing2/avatar-group.png" width="120" />
          </div>

          <div class="t-text-center">
            <h3 class="t-text-[#101828] t-font-semibold t-text-xl">Still have questions?</h3>
            <p class="!t-mb-0 t-text-[#475467] t-text-lg">
              Can’t find the answer you’re looking for? Please chat to our friendly team.
            </p>
          </div>

          <v-btn @click="contactSupport" color="primary" depressed class="t-capitalize t-font-semibold t-rounded-lg" width="169" height="48">
            Get in touch
          </v-btn>
        </div>

        <div v-if="user.stripe.subscriptionId" class="t-mt-24 t-pt-8 t-pb-10 t-flex t-items-center t-flex-col t-space-y-8 t-bg-white t-rounded-2xl">

          <div class="t-text-center">
            <h3 class="t-text-[#101828] t-font-semibold t-text-xl">Need to cancel your subscription?</h3>
            <p class="!t-mb-0 t-text-[#475467] t-text-lg">
              You can cancel your subscription easily here.
            </p>
          </div>

          <v-btn @click="cancelSubscription" color="primary" depressed class="t-capitalize t-font-semibold t-rounded-lg" width="169" height="48">
            Cancel my subscription
          </v-btn>
        </div>
      </v-container>
    </div>
  </div>
</template>


<script>
import PaymentServices from "@/services/PaymentServices";
import { Crisp } from "crisp-sdk-web";
import Swal from "sweetalert2";
import { mapState } from "vuex";
export default {
  data() {
    return {
      userCurrentPlan: null,
      billing: 1,
      plans: [
        {
          stripeId: process.env.VUE_APP_STRIPE_PLAN_1_MONTHLY,
          subscription: 1,
          title: "Developper 1K",
          price: 29,
          features: [
            { number: "1,000", text: "credits", info: "1 'credit' is used for each request you make, such as data retrieval or enrichment. Every request consumes one credit from your available balance." },
            { number: "1,000", text: "Daily Request Limit", info: "The daily limit is the maximum number of requests you can make in a single day. Once this limit is reached, you won't be able to make more requests until the next day when the limit resets." }
          ],
        },
        {
          stripeId: process.env.VUE_APP_STRIPE_PLAN_2_MONTHLY,
          subscription: 1,
          title: "Production 20K",
          price: 299,
          features: [
            { number: "20,000", text: "credits", info: "1 'credit' is used for each request you make, such as data retrieval or enrichment. Every request consumes one credit from your available balance." },
            { number: "20,000", text: "Daily Request Limit", info: "The daily limit is the maximum number of requests you can make in a single day. Once this limit is reached, you won't be able to make more requests until the next day when the limit resets." }
          ],
        },
        {
          stripeId: process.env.VUE_APP_STRIPE_PLAN_3_MONTHLY,
          subscription: 1,
          title: "Business 100K",
          price: 999,
          features: [
            { number: "100,000", text: "credits", info: "1 'credit' is used for each request you make, such as data retrieval or enrichment. Every request consumes one credit from your available balance." },
            { number: "20,000", text: "Daily Request Limit", info: "The daily limit is the maximum number of requests you can make in a single day. Once this limit is reached, you won't be able to make more requests until the next day when the limit resets." }
          ],
        },
        {
          stripeId: process.env.VUE_APP_STRIPE_PLAN_1_PAYG,
          subscription: 0,
          title: "Pay As You Go 20K",
          price: 600,
          features: [
            { number: "20,000", text: "credits", info: "1 'credit' is used for each request you make, such as data retrieval or enrichment. Every request consumes one credit from your available balance." },
            { number: "20,000", text: "Daily Request Limit", info: "The daily limit is the maximum number of requests you can make in a single day. Once this limit is reached, you won't be able to make more requests until the next day when the limit resets." },
            { number: "", text: "Credits are lifetime available" },
          ],
        },
        {
          stripeId: process.env.VUE_APP_STRIPE_PLAN_2_PAYG,
          subscription: 0,
          title: "Pay As You Go 100K",
          price: 2000,
          features: [
            { number: "100,000", text: "credits", info: "1 'credit' is used for each request you make, such as data retrieval or enrichment. Every request consumes one credit from your available balance." },
            { number: "20,000", text: "Daily Request Limit", info: "The daily limit is the maximum number of requests you can make in a single day. Once this limit is reached, you won't be able to make more requests until the next day when the limit resets." },
            { number: "", text: "Credits are lifetime available" },
          ],
        },
        {
          stripeId: process.env.VUE_APP_STRIPE_PLAN_3_PAYG,
          subscription: 0,
          title: "Pay As You Go 500K",
          price: 4000,
          features: [
            { number: "500,000", text: "credits", info: "1 'credit' is used for each request you make, such as data retrieval or enrichment. Every request consumes one credit from your available balance." },
            { number: "50,000", text: "Daily Request Limit", info: "The daily limit is the maximum number of requests you can make in a single day. Once this limit is reached, you won't be able to make more requests until the next day when the limit resets." },
            { number: "", text: "Credits are lifetime available" },
          ],
        },
      ],
      features: [
        [
          "Unused credits automatically roll over",
          "Access to all API endpoints",
          "Real-time data gathering (no cache)",
        ],
        [
          "100% GDPR & CCPA compliant",
          "Interactive documentation",
          "Find LinkedIn account from email address",
        ],
        [
          "Find company LinkedIn from domain",
          "Extract person data profile",
          "Extract company data profile",
        ],
      ],
      faqs: [
        [
          {
            title: "Can I use ScrapIn for just one month?",
            description:
              "Yes, you can subscribe to a monthly plan and cancel it once you have finished your work. Alternatively, you can opt for a pay-as-you-go option to receive lifetime available credits.",
            expanded: false,
          },
          {
            title: "Can I cancel my subscription at any time?",
            description:
              "Yes, you can cancel your monthly subscription at any time without any commitment.",
            expanded: false,
          },
          {
            title: "Do I lose my credits if I cancel my subscription?",
            description:
              "Yes, your credits will be reset if you cancel your subscription. However, all unused credits will roll over month after month as long as you keep your subscription active.",
            expanded: false,
          },
          {
            title: "Can I downgrade or upgrade my plan?",
            description:
              "Yes. You can cancel, upgrade, or downgrade your plan at anytime. Downgrades will take place at the end of your plan cycle, while upgrades & cancelation will take place immediately.",
            expanded: false,
          },
        ],
        [
          {
            title: "Can I share my subscription with my team?",
            description:
              "Currently, we do not offer a team plan, so subscriptions cannot be shared with teammates.",
            expanded: false,
          },
          {
            title: "Do unused credits from the previous month carry over?",
            description:
              "Yes, unused credits from the previous month will carry over month after month as long as your subscription remains active.",
            expanded: false,
          },
          {
            title: "What is a credit, and how is it used?",
            description:
              "A credit is consumed each time you search for information, as it triggers a live search.",
            expanded: false,
          },
          {
            title: "What is the difference between monthly and pay-as-you-go plans?",
            description:
              "Both monthly and pay-as-you-go billing options offer the same ScrapIn enrichment services. The primary differences lie in the billing frequency, the way credits are purchased, and the cost per credit unit.",
            expanded: false,
          },
        ],
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    isSubscription() {
      return this.billing === 1;
    },
    displayPricing() {
      let plansFiltered = this.plans.filter(
        (plan) => plan.subscription === this.billing
      );

      this.userCurrentPlan = plansFiltered.find(
        (plan) => plan.stripeId === this.user.stripe.priceId
      );

      if (this.userCurrentPlan === undefined) return plansFiltered;

      let priceCurrentPlan = this.userCurrentPlan.price;

      //plansFiltered = plansFiltered.filter((plan) => plan.price >= priceCurrentPlan);

      return plansFiltered;
    },
  },
  methods: {
    contactSupport() {
      Crisp.chat.open();
      Crisp.message.sendText("Hello! I need more information about pricing plans.");
    },
    contactSales() {
      Crisp.chat.open();
      Crisp.message.sendText("Hello! I am interested in upgrading my current plan to the enterprise plan.");
    },
    async cancelSubscription() {
      Swal.fire({
        icon: "warning",
        title: "Cancel your subscription?",
        text: "If you cancel your subscription, all your credits will be reset!",
        confirmButtonText: 'Cancel my plan and reset my credits',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          Crisp.chat.open();
          Crisp.message.sendText("Hello! Please cancel my subscription: " + this.user.stripe.subscriptionId);
        }
      });
    },
    async checkout (plan) {
      if (this.userCurrentPlan && plan.subscription == 1 && plan.price < this.userCurrentPlan.price) {
        Swal.fire({
          icon: "warning",
          title: "Downgrade your subscription?",
          text: "If you choose this plan, your subscription will be downgraded in your next billing cycle.",
          confirmButtonText: 'Downgrade my plan',
          showCancelButton: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            var response = await PaymentServices.downgrade({
              price: plan.stripeId
            })

            this.user.stripe.priceId = plan.stripeId

            Swal.fire({
              icon: "success",
              title: "Your subscription has been downgrade",
              text: "Your new subscription will start at your next billing cycle",
              showConfirmButton: false,
              timer: 4000,
            });
          }
        });
      } else if (this.userCurrentPlan && plan.subscription == 1 && plan.stripeId == this.userCurrentPlan.stripeId) {
          Swal.fire({
            icon: "warning",
            title: "Restart your billing cycle?",
            text: "If you restart your billing cycle, you will receive all credit for your current plan now and your billing cycle will restart from today.",
            confirmButtonText: 'Restart my billing cycle',
            showCancelButton: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              var response = await PaymentServices.checkout({
                mode: plan.subscription == 1 ? 'subscription' : 'payment',
                line_items: [
                  {
                    price: plan.stripeId,
                    quantity: 1,

                  }
                ]
              })
              if (response.data.success == true) {
                window.location.href = response.data.url
              }
            }
          });
      } else {
        var response = await PaymentServices.checkout({
          mode: plan.subscription == 1 ? 'subscription' : 'payment',
          line_items: [
            {
              price: plan.stripeId,
              quantity: 1,

            }
          ]
        })
        if (response.data.success == true) {
          window.location.href = response.data.url
        }
      }
    },
    isCurrentPlan(stripeId){
      return stripeId === this.user.stripe.priceId
    }
  },
};
</script>

<style lang="scss">
.v-application:has(.pricing2) {
  background-color: #fff !important;
}
</style>

<style lang="scss" scoped>
.pricing2 * {
  font-family: "Inter", sans-serif !important;
}
</style>
