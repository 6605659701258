<template>
  <div v-if="user == null"></div>
  <div v-else>
    <v-container class="pricing2 !t-max-w-[1280px]">
      <div class="t-pt-24 t-flex t-items-center t-flex-col">
        <h1 class="t-font-semibold t-text-5xl t-text-[#101828] t-mb-6">
          Upgrade Your Plan
        </h1>
        <p class="t-text-xl t-text-[#475467] !t-mb-0">
          Simple, transparent pricing that grows with you.
        </p>

        <v-tabs v-model="billing" class="t-my-12" hide-slider height="76">
          <div class="t-flex t-h-14 t-justify-center t-w-full">
            <div
              class="t-flex t-bg-gray-50 t-rounded-xl t-border t-border-solid t-border-[#eaecf0] t-p-[6px] t-relative"
            >
              <v-tab
                v-for="(n, i) in ['Integrating partners', 'Enterprise plans']"
                :key="n"
                class="t-py-2 t-p-3 t-tracking-wide t-rounded-md t-capitalize !t-text-[#667085] t-text-base t-font-semibold"
                :class="{ 't-mr-1': !i }"
                active-class="t-bg-white !t-text-[#344054] t-shadow-[0px_1px_2px_0px_#1018280F,0px_1px_3px_0px_#1018281A]"
              >
                {{ n }}
              </v-tab>
            </div>
          </div>

          <v-tabs-items
            v-model="billing"
            touchless
            class="t-w-full t-pt-[76px]"
          >
            <v-tab-item v-for="n in 2" :key="n">
              <v-row class="t-justify-center">
                <v-col
                  v-for="(plan, i) in displayPricing"
                  :key="i"
                  cols="12"
                  md="6"
                  lg="3"
                  class="t-relative"
                >
                  <div class="t-px-6 t-pb-12">
                    <div
                      class="t-text-[#101828] t-font-semibold t-text-xl t-mb-4"
                    >
                      {{ plan.title }}
                    </div>

                    <hr
                      class="t-border-0 t-border-t t-border-solid t-border-[#eaecf0] t-mx-[-100%]"
                    />

                    <div
                      class="t-mt-8 t-mb-6 t-flex t-gap-3 t-flex-col t-text-[#475467]"
                    >
                      <div class="t-mb-[7px]">
                        <span
                          class="t-text-[#101828] t-text-5xl t-font-semibold"
                        >
                          ${{ formatPrice(plan.price) }}
                        </span>
                        {{ isEnterprise ? "per year" : "per month" }}
                      </div>
                      <span
                        v-for="feature in plan.features"
                        :key="feature.number"
                      >
                        <span class="t-font-bold">{{ feature.number }}</span>
                        {{ feature.text }}
                      </span>
                    </div>

                    <v-btn
                      block
                      outlined
                      class="t-capitalize t-font-semibold t-rounded-lg hover:t-bg-[#216fed]/10"
                      :class="{
                        't-bg-[#216fed]/10': isCurrentPlan(plan.stripeId),
                        't-bg-white': !isCurrentPlan(plan.stripeId)
                      }"
                      @click="checkout(plan)"
                      :color="$theme.primary"
                    >
                      {{
                        isCurrentPlan(plan.stripeId)
                          ? "Restart my billing cycle"
                          : "Select"
                      }}
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </div>

      <div class="t-mb-[72px]">
        <div
          class="t-bg-gray-50 t-py-[22px] t-px-6 t-text-[#101828] t-font-semibold t-text-lg"
        >
          All plans include
        </div>

        <v-row class="t-overflow-hidden" no-gutters>
          <v-col
            v-for="(feature, i) in features"
            :key="i"
            cols="12"
            md="6"
            lg="4"
          >
            <div v-for="text in feature" :key="text">
              <div class="t-py-[22px] t-px-6 t-flex t-gap-2 t-items-center">
                <div>
                  <v-img
                    src="@/assets/icons/check.svg"
                    width="18"
                    height="18"
                  />
                </div>
                <span class="t-text-[#101828] t-font-medium">{{ text }}</span>
              </div>

              <hr
                class="t-border-0 t-border-t t-border-solid t-border-[#eaecf0] t-mx-[-100%]"
              />
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>

    <div class="t-bg-gray-50 t-py-24">
      <v-container class="!t-max-w-[1280px]">
        <div class="t-mb-24">
          <div class="t-text-center t-mb-16">
            <h2 class="t-font-semibold t-text-4xl t-text-[#101828] t-mb-5">
              Frequently asked questions
            </h2>
            <p class="t-text-[#475467] t-text-xl !t-mb-0">
              Everything you need to know about the product and billing.
            </p>
          </div>

          <v-row class="md:t-space-x-">
            <v-col v-for="(item, i) in faqs" :key="i" cols="12" lg="6">
              <div
                v-for="(faq, idx) in item"
                :key="`faq-${idx}`"
                class="t-pt-6 t-border-t t-border-solid t-border-[#eaecf0] t-mb-8"
              >
                <div
                  class="t-flex t-justify-between t-cursor-pointer"
                  @click="faq.expanded = !faq.expanded"
                >
                  <div>
                    <span class="t-text-[#101828] t-font-medium t-text-lg">{{
                      faq.title
                    }}</span>

                    <v-slide-y-transition hide-on-leave>
                      <div v-if="faq.expanded" class="t-text-[#475467] t-mt-2">
                        {{ faq.description }}
                      </div>
                    </v-slide-y-transition>
                  </div>

                  <div>
                    <v-img
                      :src="
                        require(`@/assets/icons/${
                          faq.expanded ? 'minus' : 'plus'
                        }-circle.svg`)
                      "
                      width="24"
                      height="24"
                    />
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import PaymentServices from "@/services/PaymentServices";
import Swal from "sweetalert2";
import { mapState } from "vuex";
export default {
  data() {
    return {
      userCurrentPlan: null,
      billing: 1,
      plans: [
        {
          stripeId: process.env.VUE_APP_STRIPE_PLAN_1_ENTERPRISE,
          subscription: 1,
          title: "Enterprise 500k",
          price: 4000,
          features: [
            { number: "500,000", text: "credits" },
            { number: "50,000", text: "requests per day" },
            { number: "$0.01", text: "per overage-credit" },
          ],
        },
        {
          stripeId: process.env.VUE_APP_STRIPE_PLAN_2_ENTERPRISE,
          subscription: 1,
          title: "Enterprise 2M",
          price: 8000,
          features: [
            { number: "2,000,000", text: "credits" },
            { number: "200,000", text: "requests per day" },
            { number: "$0.008", text: "per overage-credit" },
          ],
        },
        {
          stripeId: process.env.VUE_APP_STRIPE_PLAN_3_ENTERPRISE,
          subscription: 1,
          title: "Enterprise 5M",
          price: 15000,
          features: [
            { number: "5,000,000", text: "credits" },
            { number: "500,000", text: "requests per day" },
            { number: "$0.004", text: "per overage-credit" },
          ],
        },
        {
          stripeId: process.env.VUE_APP_STRIPE_PLAN_4_ENTERPRISE,
          subscription: 1,
          title: "Enterprise 10M",
          price: 20000,
          features: [
            { number: "10,000,000", text: "credits" },
            { number: "1,000,000", text: "requests per day" },
            { number: "$0.003", text: "per overage-credit" },
          ],
        },
        {
          stripeId: process.env.VUE_APP_STRIPE_PLAN_1_PARTNER,
          subscription: 0,
          title: "Partner 50K Daily",
          price: 4000,
          features: [
            { number: "Unlimited", text: "credits" },
            { number: "50,000", text: "requests per day" },
            { number: "$0.005", text: "per overage-request" },
          ],
        },
        {
          stripeId: process.env.VUE_APP_STRIPE_PLAN_2_PARTNER,
          subscription: 0,
          title: "Partner 200K Daily",
          price: 8000,
          features: [
            { number: "Unlimited", text: "credits" },
            { number: "200,000", text: "requests per day" },
            { number: "$0.003", text: "per overage-request" },
          ],
        },
        {
          stripeId: process.env.VUE_APP_STRIPE_PLAN_3_PARTNER,
          subscription: 0,
          title: "Partner 500K Daily",
          price: 15000,
          features: [
            { number: "Unlimited", text: "credits" },
            { number: "500,000", text: "requests per day" },
            { number: "$0.002", text: "per overage-request" },
          ],
        },
        {
          stripeId: process.env.VUE_APP_STRIPE_PLAN_4_PARTNER,
          subscription: 0,
          title: "Partner 1M Daily",
          price: 20000,
          features: [
            { number: "Unlimited", text: "credits" },
            { number: "1,000,000", text: "requests per day" },
            { number: "$0.001", text: "per overage-request" },
          ],
        },
      ],
      features: [
        [
          "Unused credits automatically roll over",
          "Personal & Professional email enrichment",
          "Real-time data gathering (no cache)",
          "Find LinkedIn account from email address",
        ],
        [
          "100% GDPR & CCPA compliant",
          "CSV Enrichment",
          "API Access",
          "Zapier integration",
        ],
        [
          "Make integration",
          "Hubspot integration",
          "Salesforce integration",
          "Pipedrive integration",
        ],
      ],
      faqs: [
        [
          {
            title: "Can I use ScrapIn for just one month?",
            description:
              "Yes, you can subscribe to a monthly plan and cancel it once you have finished your work. Alternatively, you can opt for a pay-as-you-go option to receive lifetime available credits.",
            expanded: false,
          },
          {
            title: "Can I cancel my subscription at any time?",
            description:
              "Yes, you can cancel your monthly subscription at any time without any commitment.",
            expanded: false,
          },
          {
            title: "Do I lose my credits if I cancel my subscription?",
            description:
              "Yes, your credits will be reset if you cancel your subscription. However, all unused credits will roll over month after month as long as you keep your subscription active.",
            expanded: false,
          },
          {
            title: "Can I downgrade or upgrade my plan?",
            description:
              "Yes. You can cancel, upgrade, or downgrade your plan at anytime. Downgrades will take place at the end of your plan cycle, while upgrades & cancelation will take place immediately.",
            expanded: false,
          },
        ],
        [
          {
            title: "Can I share my subscription with my team?",
            description:
              "Currently, we do not offer a team plan, so subscriptions cannot be shared with teammates.",
            expanded: false,
          },
          {
            title: "Do unused credits from the previous month carry over?",
            description:
              "Yes, unused credits from the previous month will carry over month after month as long as your subscription remains active.",
            expanded: false,
          },
          {
            title: "What is a credit, and how is it used?",
            description:
              "A credit is consumed each time you search for information, as it triggers a live search.",
            expanded: false,
          },
          {
            title: "What is the difference between monthly and pay-as-you-go plans?",
            description:
              "Both monthly and pay-as-you-go billing options offer the same ScrapIn enrichment services. The primary differences lie in the billing frequency, the way credits are purchased, and the cost per credit unit.",
            expanded: false,
          },
        ],
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    isEnterprise() {
      return this.billing === 1;
    },
    displayPricing() {
      let plansFiltered = this.plans.filter(
        (plan) => plan.subscription === this.billing
      );

      this.userCurrentPlan = plansFiltered.find(
        (plan) => plan.stripeId === this.user.stripe.priceId
      );

      if (this.userCurrentPlan === undefined) return plansFiltered;

      let priceCurrentPlan = this.userCurrentPlan.price;

      //plansFiltered = plansFiltered.filter((plan) => plan.price >= priceCurrentPlan);

      return plansFiltered;
    },
  },
  methods: {
    formatPrice(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async checkout(plan) {
      if (
        this.userCurrentPlan &&
        plan.subscription == 1 &&
        plan.price < this.userCurrentPlan.price
      ) {
        Swal.fire({
          icon: "warning",
          title: "Downgrade your subscription?",
          text: "If you choose this plan, your subscription will be downgraded in your next billing cycle.",
          confirmButtonText: "Downgrade my plan",
          showCancelButton: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            var response = await PaymentServices.downgrade({
              price: plan.stripeId,
            });

            this.user.stripe.priceId = plan.stripeId;

            Swal.fire({
              icon: "success",
              title: "Your subscription has been downgrade",
              text: "Your new subscription will start at your next billing cycle",
              showConfirmButton: false,
              timer: 4000,
            });
          }
        });
      } else if (
        this.userCurrentPlan &&
        plan.subscription == 1 &&
        plan.stripeId == this.userCurrentPlan.stripeId
      ) {
        Swal.fire({
          icon: "warning",
          title: "Restart your billing cycle?",
          text: "If you restart your billing cycle, you will receive all credit for your current plan now and your billing cycle will restart from today.",
          confirmButtonText: "Restart my billing cycle",
          showCancelButton: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            var response = await PaymentServices.checkout({
              mode: "subscription",
              line_items: [
                {
                  price: plan.stripeId,
                  quantity: 1,
                },
              ],
            });
            if (response.data.success == true) {
              window.location.href = response.data.url;
            }
          }
        });
      } else {
        var response = await PaymentServices.checkout({
          mode: "subscription",
          line_items: [
            {
              price: plan.stripeId,
              quantity: 1,
            },
          ],
        });
        if (response.data.success == true) {
          window.location.href = response.data.url;
        }
      }
    },
    isCurrentPlan(stripeId) {
      return stripeId === this.user.stripe.priceId;
    },
  },
};
</script>

<style lang="scss">
.v-application:has(.pricing2) {
  background-color: #fff !important;
}
</style>

<style lang="scss" scoped>
.pricing2 * {
  font-family: "Inter", sans-serif !important;
}
</style>
